import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import CustomContainer from 'components/CustomContainer';
import bgNews from '../../../images/news/bg-news.png';
import './styles.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

const tabItem = [
  {
    title: 'CSR',
    id: 'csr',
    englishTitle: 'CSR',
  },
  {
    title: 'Informasi Lainnya',
    id: 'informasiLainnya',
    englishTitle: 'Other Information',
  },
  {
    title: 'Karir',
    id: 'karir',
    englishTitle: 'Career',
  },
  {
    title: 'Penghargaan',
    id: 'penghargaan',
    englishTitle: 'Awards',
  },
];

const CardCustom = ({ item }) => {
  return (
    <div className="card-custom grid grid-cols-12 gap-2 md:gap-12">
      <div className="col-span-12 md:col-span-4">
        <div
          className="image-thumbnails cursor-pointer"
          style={{
            backgroundImage: `url(${item?.featuredImage})`,
            height: '100%',
            minHeight: '200px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      </div>
      <div className="col-span-12 md:col-span-8 pt-1 relative">
        <Link to={`/news/${item.slug}`}>
          <h5 className="text-2xl font-medium cursor-pointer">{item.title}</h5>
        </Link>
        <p className="line-clamp-3 font-thin mt-4 text-md ">{item?.headline}</p>
        <p className="mt-3 font-thin">
          {moment(item?.date).format('MMM YY')}
        </p>
      </div>
    </div>
  );
};

const renderCardList = (data) => {
  return data.map((item, idx) => {
    return <CardCustom item={item?.node} key={idx.toString()} />;
  });
};

const NewsList = ({ data, tabActive, handleTabActive }) => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <div className="bg-black text-white font-extralight min-h-screen pt-[70px] sm:pt-0 news-root">
      {/* jumbotron */}
      <div
        className="pt-20 h-56 bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${bgNews})` }}
      >
        <CustomContainer className="w-full h-full">
          <div className="sm:px-14 xl:px-20 flex items-center w-full h-full">
            <h1 className="text-5xl font-medium"> {t('news')}</h1>
          </div>
        </CustomContainer>
      </div>
      <CustomContainer className="">
        <div className="sm:px-14 xl:px-20 flex gap-8">
          {tabItem.map((item, idx) => (
            <p
              onClick={() => handleTabActive(item.id)}
              key={idx.toString()}
              className={`${
                tabActive === item.id
                  ? 'border-b-folk-orange'
                  : 'border-b-transparent'
              } border-b-2  hover:border-b-folk-orange cursor-pointer`}
            >
              {isEnglish ? item?.englishTitle : item.title}
            </p>
          ))}
        </div>
      </CustomContainer>
      {/* News list section */}
      <CustomContainer className="py-14 sm:pb-32 sm:pt-10">
        <div />
        {data?.length ? (
          <div className="grid sm:px-14 xl:px-20 space-y-8">
            {renderCardList(data)}
          </div>
        ) : (
          <div className="text-base sm:text-md flex items-center justify-center h-56">
            Sorry, there is no news entry yet
          </div>
        )}
      </CustomContainer>
    </div>
  );
};

CardCustom.propTypes = {
  item: PropTypes.shape(),
};

export default NewsList;
