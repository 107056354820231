import React, { useEffect, useState } from 'react';
import Pages from 'components/Pages';
import NewsList from 'components/pages/News/NewsList';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const News = ({ data }) => {
  const [tabActive, setTabActive] = useState('csr');
  const [dataFiltered, setDataFiltered] = useState(null);

  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath?.includes('/en/');
  const lang = isEnglish ? "en" : "id"

  useEffect(() => {
    if (data.allNewsJson) {
      const filtered = data.allNewsJson.edges.filter(
        (item) => item.node.type === lang && item.node.category === tabActive,
      );
      setDataFiltered(filtered);
    }
  }, [tabActive, data.allNewsJson, lang]);

  return (
    <Pages title="FOLK - News">
      <NewsList
        data={dataFiltered}
        handleTabActive={(e) => setTabActive(e)}
        tabActive={tabActive}
      />
    </Pages>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNewsJson {
      edges {
        node {
          category
          id
          date
          content
          slug
          title
          headline
          featuredImage
          type
        }
      }
      totalCount
    }
  }
`;

export default News;
